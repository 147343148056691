<template>
  <div >
    <el-dialog v-bind="$attrs" v-on="$listeners" @open="onOpen" @close="onClose" title="在线充值">

      <el-steps :active="step" finish-status="success" simple style="margin-bottom: 20px">
        <el-step title="输入充值金额" ></el-step>
        <el-step title="扫码充值" ></el-step>
      </el-steps>

      <div v-if="step == 1"> 
      <el-form ref="recharge" :model="rechargeParam" size="mini" label-width="150px">
        <el-form-item label="充值金额" prop="money">
          <el-input-number v-model="rechargeParam.money" placeholder="请输入充值金额" clearable :style="{width: '50%'}"
          :precision="1"
          :step="1"
          :min="0.1"
          >
          </el-input-number>
        </el-form-item>
      </el-form>
      </div>
    
      <div v-if="step == 2">

        <el-card>
            <div class="qrCodeNote">请使用微信或支付宝扫码充值，金额：￥{{result.money}}</div>
            <div id="qrCodeId" ref="qrCodeId"></div>
        </el-card>
        
        </div>

      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handelConfirm" :loading="loading" :disabled="step!=1">立即充值</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { recharge, getBalanceHistory } from "@/api/payorder";
import QRCode from'qrcodejs2'

let qrcode

export default {
  inheritAttrs: false,
  components: {},
  props: [],
  data() {
    return {
      rechargeParam: {
        money: 100,
      },
      result: {},
      step: 1,
      loading: false,      
      monitor: null,
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onOpen() {},
    onClose() {
      this.close()
    },
    close() {
      this.step = 1
      this.result = {}
      if(this.$refs.qrCodeId) {
        this.$refs.qrCodeId.innerHTML = ''
      }
      this.$emit('update:visible', false)
    },
    handelConfirm() {
      this.$refs['recharge'].validate(valid => {
        if (!valid) return
        const that = this
        that.loading = true
        recharge(this.rechargeParam).then(res=>{
            that.loading = false
            if(res.code === 200) {
                that.result = res.data
                that.createQrCode(res.data.payUrl)
                that.step = 2
                that.monitor = setInterval(()=>{
                  that.queryRechargeResult(res.data.orderId)
                },3000)
            } else {
                this.$message.error(res.message)
            }
        }).catch(err => {
            that.loading = false
        })
      })
    },
    queryRechargeResult(orderId){
      const param = {
        billId: orderId,
        pageNo: 1,
        pageSize: 1,
        orderByCreated: 'DESC'
      }
      const that = this
      getBalanceHistory(param).then(res => {
        if(res.code === 200) {
          if(res.data.rows.length!=0) {
            clearInterval(that.monitor)
            that.$alert("充值完成")
            that.close()
            that.monitor = null
          }
        }
      })
    },
    createQrCode(text) {
      this.$nextTick (function () {
        if(!document.getElementById("qrCodeId")) return;
        document.getElementById("qrCodeId").innerHTML = ""
        qrcode = new QRCode("qrCodeId", {
            text: text,
            width: 200,
            height: 200,
            colorDark:'#000000',
            colorLight:'#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
      })
    }
  }
}

</script>
<style scoped>
#qrCodeId {
    width: 200px;
    height: 200px;
    margin: 0 auto;
}
</style>
