<template>
  <div>
    <div class="contents">
      <div class="balance" style="margin-bottom: 10px">
        <el-table :data="balance">
          <el-table-column label="账户可用余额" width="200" align="center">
            <template slot-scope="scope">
              <span class="balance">￥ {{ scope.row.balance }}</span>
            </template>
          </el-table-column>

          <el-table-column label="待入账" width="200" align="center">
            <template slot-scope="scope">
              <span class="settleAccount"
                >￥ {{ scope.row.settleAccount }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="当日收款资金会在下个工作日自动结算到账户可用余额"
                  placement="right-start"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>

          <el-table-column label="提现中" width="200" align="center">
            <template slot-scope="scope">
              {{ scope.row.withdrawal }}
            </template>
          </el-table-column>

          <el-table-column label="" align="left">
            <el-button type="primary" @click="() => (rechargeFlag = true)"
              size="mini"
              >充值</el-button
            >

            <el-button type="primary" size="mini" @click="openWithdraw">提现</el-button>

            <el-button @click="bindBank" size="mini">绑定提现银行卡</el-button>
          </el-table-column>

          <el-table-column>
            <el-button type="text" @click="withdrawHistory"
              >查看最近一次提现记录</el-button
            >
          </el-table-column>
        </el-table>
      </div>

      <div class="tableSearch">
        <el-form
          :inline="true"
          ref="searchForm"
          :model="searchForm"
          label-width="90px"
        >
          <el-form-item label="起止时间" style="margin-bottom: 10px">
            <el-date-picker
              v-model="searchForm.date"
              type="daterange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="交易对象" >
            <el-input v-model="searchForm.targetName" style="margin-bottom: 10px" clearable></el-input>
          </el-form-item>

          <el-form-item label="财务类型" >
            <el-select v-model="searchForm.operations" multiple clearable>
              <!--过滤掉第一个空值-->
            <el-option
                  v-for="(item,index) in status.filter(s => s!='')"
                  :key="index"
                  :label="item"
                  :value="index+1"
                >
            </el-option>
                </el-select>
          </el-form-item>

          <el-form-item label="单据编号" >
            <el-input v-model="searchForm.billId" style="margin-bottom: 10px" clearable></el-input>
          </el-form-item>

          <el-button @click="createSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button @click="chongzhi">重置</el-button>
        </el-form>
      </div>

      <div class="mainbox">
        <el-table
          :data="tableData"
          border
          :header-cell-style="{ background: '#f7f7f7', color: '#666' }"
          stripe
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            width="40"
            header-align="center"
            align="center"
            fixed
          >
          </el-table-column>

          <el-table-column label="入账时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.createTime }}
            </template>
          </el-table-column>


          <el-table-column label="财务类型">
            <template slot-scope="scope">
              <el-tag :type="types[scope.row.operation]">{{
                status[scope.row.operation]
              }}</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="金额（元）">
            <template slot-scope="scope">
              <span v-if="scope.row.operation == 1 || scope.row.operation == 6 || scope.row.operation == 7" class="addAccountMoney"
                >+{{ scope.row.value }}</span
              >
              <span v-else class="subAccountMoney">-{{ scope.row.value }}</span>
            </template>
          </el-table-column>

          <el-table-column label="交易对象" prop="targetName">            
          </el-table-column>

          <el-table-column label="单据编号" width="200">
            <template slot-scope="scope">
              {{ scope.row.billId }}
            </template>
          </el-table-column>

          <el-table-column label="备注">
            <template slot-scope="scope">
              {{ scope.row.remark }}
            </template>
          </el-table-column>

        </el-table>
        <div class="paginations">
          <el-pagination
            background
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 50, 100, 200, 400]"
            @current-change="changePage"
            :current-page="page"
            :page-size="pageSize"
            @size-change="handleSizeChange"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      title="申请提现"
      :visible.sync="withdrawalFlag"
      :before-close="layerClose"
      width="700px"
    >
        <div class="withdrawable">可提现金额：￥{{balance[0].balance}}元</div>
      <el-form
        :inline="true"
        ref="information"
        :model="withdrawal"
        label-width="100px"
      >
        <el-form-item label="提现金额" style="margin-bottom: 10px">
          <el-input-number
            v-model="withdrawal.money"
            clearable
            style="width: 200px"
            :precision="2"
            :step="0.1"
            :min="0.1"
            :max="balance[0].balance"
            placeholder="请输入提现金额"
          ></el-input-number>
        </el-form-item>
        <label class="wdtip">免手续费, 周末银行休息, 预计1个工作日到账</label>
      </el-form>
      <el-button
        type="primary"
        @click.native.prevent="applyWithdraw"
        :loading="withdrawing"
        style="margin-left: 100px; margin-top: 20px"
        >确认提交</el-button
      >
    </el-dialog>

    <el-dialog
      :title="qrCodeTitle"
      :visible.sync="showQrCode"
      :before-close="layerClose"
      width="600px"
    >
      <el-card>
        <div class="qrCodeNote">{{ qrCodeNote }}</div>
        <img :src="qrCodeUrl" class="codesize" />
      </el-card>
    </el-dialog>

    <el-dialog
      title="最近一次提现记录"
      :visible.sync="withdrawHistoryFlag"
      :before-close="layerClose"
      width="800px"
    >
      <div style="height: 120px">
        <el-steps direction="vertical" :active="withdrawHistoryData.status + 1">
          <el-step
            title="申请提现"
            :description="withdrawHistoryData.createTime"
          ></el-step>
          <el-step
            v-if="withdrawHistoryData.status == 0"
            title="提现中"
            :description="withdrawHistoryData.updateTime"
          ></el-step>
          <el-step
            v-if="withdrawHistoryData.status == 1"
            title="提现完成"
            :description="
              withdrawHistoryData.updateTime + ' ' + withdrawHistoryData.remark
            "
          ></el-step>
          <el-step
            v-if="withdrawHistoryData.status == 2"
            title="提现失败"
            :description="
              withdrawHistoryData.updateTime + ' ' + withdrawHistoryData.remark
            "
          ></el-step>
        </el-steps>
      </div>
      <div class="hsmoney red">
        提现金额：￥{{ withdrawHistoryData.money }} 元，免手续费
      </div>
    </el-dialog>

    <recharge
      :visible.sync="rechargeFlag"
      @close="getTopSearch"
    ></recharge>
  </div>
</template>

<script>
import Vue from "vue";
import Clipboard from "clipboard";
import QRCode from "qrcodejs2";
import {
  getAccountBalance,
  getBalanceHistory,
  applyWithdraw,
  getWithdrawal,
  repeatNotify,
} from "@/api/payorder";
import passWord from "@/components/password";
import { QrCode } from "@/api/login";
import Recharge from "./recharge.vue";

let qrcode;

let moment = require("moment");
Vue.prototype.Clipboard = Clipboard;
export default {
  name: "Orderss",
  components: { passWord, Recharge },
  data() {
    return {
      searchForm: {
        date: "",
      },
      balance: [
        {
          balance: '-',
          withdrawal: '-',
          settleAccount: '-'
        },
      ],
      withdrawal: {
        money: 1,
      },
      withdrawing: false,
      newOrderArr: [],
      total: 0,
      page: 1,
      pageSize: 20,
      faceFileList: [],
      backFileList: [],
      tableData: [],
      active: 1,
      withdrawalFlag: false,
      banks: [],
      bankAddresses: [],
      title: "使用专业持牌的第三方支付机构收款，业务安全合规",
      info: {},
      statusFlag: false,
      status: [
        "",
        "收款",
        "提现",
        "退款",
        "提现手续费",
        "余额支付",
        "充值",
        "退款到账"
      ],
      types: ["", "success", "", "danger", "", "", "success","success"],
      showWithdraw: false,
      showQrCode: false,
      qrCodeTitle: "请使用当前登录账号的手机号微信扫码",
      qrCodeUrl: "",
      qrCodeNote: "",
      submitLoading: false,
      withdrawHistoryFlag: false,
      withdrawHistoryData: {},
      yeePayMerchant: {
        type: 1,
      },
      rules: {
        type: [
          {
            required: true,
            message: "组织类型不能为空",
            trigger: "change",
          },
        ],
      },
      typeOptions: [
        {
          label: "个人小微商户",
          value: 1,
        },
        {
          label: "企业/个体工商户",
          value: 2,
        },
      ],
      rechargeFlag: false,
    };
  },
  methods: {
    withdrawHistory() {
      const that = this;
      getWithdrawal({}).then((res) => {
        if (res.code === 200) {
          if (!res.data) {
            return this.$message.error("没有提现记录");
          }

          that.withdrawHistoryFlag = true;
          that.withdrawHistoryData = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getLoginQrCode() {
      QrCode().then((res) => {
        if (res.code === 200) {
          this.qrCodeUrl = res.data.qrCodeUrl;
          this.showQrCode = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    createQrCode(texts) {
      this.$nextTick(function () {
        if (!document.getElementById("qrCodeId")) return;
        document.getElementById("qrCodeId").innerHTML = "";
        qrcode = new QRCode("qrCodeId", {
          text: texts,
          width: 200,
          height: 200,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    },
    bindBank() {
      this.$router.push("/yeepay");
    },
    openWithdraw() {
      this.withdrawalFlag = true;
    },

    handleChangeFace(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    handleChangeBack(file, fileList) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
    },
    getAccountBalance() {
      getAccountBalance({}).then((res) => {
        if (res.code === 200) {
          this.balance = [res.data];
        } else {
          this.$message.error(res.message);
        }
      });
    },
    applyWithdraw() {
      const that = this
      that.withdrawing = true
      applyWithdraw(this.withdrawal).then((res) => {
        that.withdrawing = false
        if (res.code === 200) {
          this.$message.success(res.message);
          this.layerClose();
          this.getAccountBalance();
        } else if (res.code === 2100) {
          this.qrCodeNote = res.message;
          this.getLoginQrCode();
        } else if (res.code === 2200) {
          this.withdrawal.money = this.balance[0].platformAccountBalance;
          this.$message.error(res.message);
        } else {
          this.$message.error(res.message);
        }
      }).catch(err => {
        that.withdrawing = false
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTopSearch();
    },
    createSearch() {
      this.page = 1;
      this.getTopSearch();
    },
    chongzhi() {
      this.searchForm = {
        date: "",
      };
      this.getTopSearch();
    },
    getTopSearch() {
      this.getAccountBalance();
      var that = this;
      var datas = that.searchForm;
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      datas.startTime =
        that.searchForm.date.length > 0
          ? moment(that.searchForm.date[0]).format("YYYY-MM-DD 00:00:00")
          : "";
      datas.endTime =
        that.searchForm.date.length > 0
          ? moment(that.searchForm.date[1]).format("YYYY-MM-DD 23:59:59")
          : "";
      getBalanceHistory(datas).then((res) => {
        console.log(res);
        if (res.code === 200) {
          var nodataArr = res.data.rows || [];
          that.tableData = nodataArr;
          that.total = res.data.totalRows;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    changePage(newPage) {
      var that = this;
      if (that.page === newPage) {
        return;
      }
      that.page = newPage;
      that.getTopSearch();
    },
    layerClose() {
      this.withdrawalFlag = false;
      this.statusFlag = false;
      this.showQrCode = false;
      this.withdrawHistoryFlag = false;
      this.rechargeFlag = false;
    },
  },
  mounted() {
    this.getTopSearch();
  },
};
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #07c160;
  color: #fff;
}
.el-tabs__nav-wrap::after {
  height: 1px;
}
.el-form-item__label {
  font-size: 12px;
}

.caozuoqu {
  float: right;
  display: block;
  padding: 5px 0 10px;
  overflow: hidden;
}
.goodinfos p {
  color: #333;
  padding-left: 60px;
  display: block;
  text-align: left;
}
.goodinfos span {
  width: 60px;
  display: inline-block;
  text-align: left;
  color: #999;
  margin-left: -50px;
}
.kuanleft {
  float: left;
  width: 70%;
}
.kuanRight {
  float: right;
  width: 15%;
  display: block;
}
.kuanTT {
  clear: both;
  display: block;
  overflow: hidden;
  padding: 5px 0;
}
.kuanTitle {
  display: block;
  font-size: 16px;
  color: #333;
  line-height: 40px;
  font-weight: bold;
}
.kuanBtn {
  width: 100%;
  padding: 62px 0;
  text-align: center;
  color: #fff;
  font-size: 16px;
  line-height: 42px;
  display: block;
  font-weight: bold;
  background-color: #07c160;
  cursor: pointer;
  border-radius: 10px;
  border: none;
}
.codesize {
  width: 200px;
  height: 200px;
  display: block;
  border: 5px solid #eee;
  margin: 0 auto;
  border-radius: 10px;
}
.kuantiao {
  display: block;
  overflow: hidden;
}
.kuantiao ol {
  float: left;
  width: 25%;
  font-size: 16px;
  color: #333;
  line-height: 40px;
  font-weight: bold;
}
.red {
  color: #ff7900;
}
.balance {
  font-size:14px;
  font-weight: bold;
  color: rgb(252, 4, 4);
}
.qrCodeNote {
  margin-bottom: 20px;
  color: red;
  text-align: center;
}
.steps {
  width: 100%;
  margin-bottom: 30px;
}
.shopboxs {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.bankInfo ul {
  list-style: none;
}
.bankInfo li {
  margin: 10px;
}
.wdtip {
  font-size: 12px;
  margin-left: 10px;
  line-height: 35px;
  color: red;
}
.hsmoney {
  margin: 15px 0px 0px 10px;
}
.wxfee {
  font-size: 12px;
  color: red;
  margin: 20px 0px 20px 40px;
}
.addAccountMoney {
  color: rgb(255, 186, 37);
  font-weight: bold;
}
.subAccountMoney {
  color: black;
  font-weight: bold;
}
.withdrawable {
  padding: 0px 0px 15px 38px;
  font-size:12px;
}
</style>